<template>
    <div class="ds-project-watch">
      <div class="row container">
        <LayoutColumn :image_src='`${this.$route.params.way}`' :content='thirdColumn'/>
        <LayoutColumn :image_src='`${this.$route.params.way}`' :content='secondColumn'/>
        <LayoutColumn :image_src='`${this.$route.params.way}`' :content='firstColumn'/>
      </div>
         <button @click="back()"  class="primary button back">{{history!==null?'Back':'See more in our design portfolio'}}</button>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import LayoutColumn from './LayoutColumn.vue'


export default {
    components:{
      LayoutColumn
    },
    data(){
      return{
        images:[],
        history:window.history.state.back
        }
    },
    computed:{
        ...mapState(['designProjects']),
        product(){
            return this.designProjects.find(item=>item.src === `${this.$route.params.way}`)
        },
         firstColumn() {
            return this.images.slice(0, (this.images.length / 3));
        },
        secondColumn() {
            return this.images.slice((this.images.length / 3), 2 * (this.images.length / 3));
        },
        thirdColumn() {
            return this.images.slice(2 * (this.images.length / 3));
        }
    },
    methods:{
        back(){
            if(window.history.state.back!==null){
                this.$router.go(-1)
            }else{
                this.$router.push('/design-samples')
            }
        },
        arrayImages(){
            for(let i=1;i<=this.product.imagesForShow;i++){
                this.images.push(`${this.$route.params.way}-${i}`)
            }
        }
    },
    created(){
        this.arrayImages()
    }
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.ds-project-watch {
  text-align: center;
  padding: 60px 0 0;
  @include media(">=desktop") {
    padding: 120px 0 0;
  }
  .back{
    color:$white;
    padding:7px 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border-radius: 26px;
     margin-top:80px;
    @include media('>=desktop'){
      margin-top:120px;
    }
  }
    .row{
      justify-content: space-between;
    }
}
</style>


