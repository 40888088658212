<template>
    <BuilderSection :designSection="content"/>
</template>
<script>
import BuilderSection from './BuilderSection.vue'
import { mapState } from 'vuex'

export default {
    components:{
        BuilderSection
    },
    computed:{
        ...mapState(['designProjects']),
        content(){
            return this.designProjects.filter(item=>item.type==='mobile'||item.type.includes('mobile'))
        }

    }
}
</script>
