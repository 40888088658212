<template>
  <div class="column">
    <div  class="design-content__image-watch" v-for="item in content" :key="item" @click="watchContent(item)">
       <img
         v-webp:src="require(`../../assets/design-portfolio/images/${image_src}/${item}.webp`)"
         :src="require(`../../assets/design-portfolio/images/${image_src}/${item}.jpg`)"
         :alt="`photo-${item}`"
          loading="lazy"
        />
    </div>
  </div>
     <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-image">
          <button
            class="modal-close"
            @click="show=false"
            aria-label="Close"
          >
            <img
              v-svg-inline
              :data-src="require('@/assets/icons/close-modal.svg')"
              alt=""
            />
          </button>
          <div class="modal-content__image" v-if="image">
           <img
             v-webp:src="require(`../../assets/design-portfolio/images/${image_src}/${image}.webp`)"
              :src="require(`../../assets/design-portfolio/images/${image_src}/${image}.jpg`)"
              :alt="`photo-logos`"
              class="modal-content__image_img"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default{
   data(){
      return{
        show:false,
        image:''
      }
   },
    props:['image_src','content'],
    methods:{
       watchContent(item){
          this.show=true,
         this.image=item
       }
    }
}
</script>
<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.ds-project-watch {
   .column{
      margin:0 0 40px 0;
      @include media('>=tablet-xl'){
         max-width: 270px;
      }
        @include media('>=desktop'){
         max-width: 350px;
      }
      @include media('>=widescreen'){
         max-width: 400px;
      }
      @include media('>=widescreen-xl'){
         max-width: 534px;
      }
      &:last-child{
          margin-bottom:0px;
         @include media('>=tablet-xl'){
         max-width: 270px;
      }
        @include media('>=desktop'){
         max-width: 350px;
      }
      @include media('>=widescreen'){
         max-width: 430px;
      }
      @include media('>=widescreen-xl'){
         max-width: 530px;
      }
      }
      &:first-child{
        margin-right:0;
      }
      .design-content__image-watch{
      cursor:pointer;
      margin-bottom:40px;
      &:last-child{
         margin-bottom:0px;
      }
      @include media('>=desktop'){
        margin-bottom:80px;
         max-width: 350px;
      }
      @include media('>=widescreen'){
         max-width: 430px;
      }
      @include media('>=widescreen-xl'){
         max-width: 534px;
      }
      }
    } 
}
.modal-image{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  min-width: 90vw;
  max-width: max-content;
  opacity: 1;
  background: $white;
  border-radius: 16px;
  padding:45px 20px 30px;
  img.modal-content__image_img{
     max-height: 70vh;
    @include media('>=phone'){
     max-height: 80vh;
   }
   }
   @include media('>=tablet'){
     min-width: 600px;
     padding:80px 40px 40px;
   }
}
</style>
