<template>
      <div class="design-content">
         <div class="row">
              <div class="column">
                  <div  class="design-content__image" v-for="item in firstColumn" :key="item.id"  @click="watchContent(item)">
                   <img
                    v-webp:src="require(`../../assets/design-portfolio/images/${item.src}/${item.srcImage}.webp`)"
                    :src="require(`../../assets/design-portfolio/images/${item.src}/${item.srcImage}.jpg`)"
                    :alt="`photo-${item.src}`"
                    loading="lazy"
                   />
                     <img 
                     v-if="item.video"
                     class="design-content__image__play"
                      v-svg-inline
                      :data-src="require('../../assets/icons/play.svg')"
                      alt=""
                     />
                  </div>
              </div>
               <div class="column">
                  <div class="design-content__image" v-for="item in secondColumn" :key="item.id"  @click="watchContent(item)">
                  <img
                    v-webp:src="require(`../../assets/design-portfolio/images/${item.src}/${item.srcImage}.webp`)"
                    :src="require(`../../assets/design-portfolio/images/${item.src}/${item.srcImage}.jpg`)"
                    :alt="`photo-${item.src}`"
                    loading="lazy"
                   />
                      <img
                       v-if="item.video"
                       class="design-content__image__play"
                      v-svg-inline
                      :data-src="require('../../assets/icons/play.svg')"
                      alt=""
                     />
                  </div>
              </div>
              <div class="column">
                  <div class="design-content__image" v-for="item in thirdColumn" :key="item.id"  @click="watchContent(item)">
                  <img
                    v-webp:src="require(`../../assets/design-portfolio/images/${item.src}/${item.srcImage}.webp`)"
                    :src="require(`../../assets/design-portfolio/images/${item.src}/${item.srcImage}.jpg`)"
                    :alt="`photo-${item.src}`"
                    loading="lazy"
                   />
                      <img
                      v-if="item.video"
                      class="design-content__image__play"
                      v-svg-inline
                      :data-src="require('../../assets/icons/play.svg')"
                      alt=""
                     />
                  </div>
              </div>
         </div>
      </div>
<transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-image">
          <button
            class="modal-close"
            @click="show=false"
            aria-label="Close"
          >
            <img
              v-svg-inline
              :data-src="require('@/assets/icons/close-modal.svg')"
              alt=""
            />
          </button>
          <div class="modal-content" v-if="video">
            <video controls autoplay class="modal-content__video">
              <source :src="require(`@/assets/design-portfolio/videos/${video}.mp4`)" type="video/mp4">
              <p>Sorry, your browser can`t play video`</p>
            </video>
          </div>
          <div class="modal-content__image" v-if="image">
           <img
             v-webp:src="require(`../../assets/design-portfolio/images/logos/${image}.webp`)"
              :src="require(`../../assets/design-portfolio/images/logos/${image}.jpg`)"
              :alt="`photo-logos`"
              loading="lazy"
              class="modal-content__image_img"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
    data() {
        return {
          show:false,
          video:'',
          image:''
        }
    },
    props:['designSection'],
    methods:{
      watchContent(item){
        if(item.type==='logos'){
          this.show=true
          this.image=item.srcImage
        }
        else if(item.video){
          this.show=true
          this.video=item.videoSrc
        }else{
          this.$router.push(`/design-samples/${item.src}`)
        }
      }
    },
        computed: {
        firstColumn() {
            return this.designSection.slice(0, (this.designSection.length / 3));
        },
        secondColumn() {
            return this.designSection.slice((this.designSection.length / 3), 2 * (this.designSection.length / 3));
        },
        thirdColumn() {
            return this.designSection.slice(2 * (this.designSection.length / 3));
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.design-content{
    .column{
      margin:0 0 40px 0;
        @include media('>=tablet-xl'){
         max-width: 270px;
      }
      @include media('>=desktop'){
         max-width: 350px;
         margin:0;
      }
      @include media('>=widescreen'){
         max-width: 400px;
      }
      @include media('>=widescreen-xl'){
         max-width: 534px;
      }
      &:last-child{
        margin-bottom:0px;
        @include media('>=tablet-xl'){
         max-width: 270px;
        }
         @include media('>=desktop'){
         max-width: 350px;
      }
      @include media('>=widescreen'){
         max-width: 430px;
      }
      @include media('>=widescreen-xl'){
         max-width: 530px;
      }
      }
      &:first-child{
        margin-right:0;
      }
      .design-content__image{
      cursor:pointer;
      margin-bottom:40px;
      &:last-child{
        margin-bottom:0px;
      }
       @include media('>=tablet-xl'){
         max-width: 270px;
      }
      @include media('>=desktop'){
         max-width: 350px;
         margin-bottom:80px;
      }
      @include media('>=widescreen'){
         max-width: 430px;
      }
      @include media('>=widescreen-xl'){
         max-width: 534px;
      }

      img{
        border-radius: 20px;
        min-width: 100%;
      }
      border-radius: 20px;
      box-shadow:0px 0px 30px rgba(0, 0, 0, 0.4);
      position: relative;
      &__play{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        cursor:pointer;
      }
}
    }
    .row{
        justify-content: space-around;
    }
}
.modal-content__video{
  width:250px;
  height: 250px;

  @include media('>=tablet'){
    width:400px;
    height: 400px;
  }
   @include media('>=desktop'){
    width:450px;
    height: 450px;
  }
}
.modal-image{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  min-width: 90vw;
  max-width: max-content;
  opacity: 1;
  background: $white;
  border-radius: 16px;
  padding:45px 20px 30px;
  img.modal-content__image_img{
     max-height: 70vh;
    @include media('>=phone'){
     max-height: 80vh;
   }
   }
   @include media('>=tablet'){
     min-width: 600px;
     padding:80px 40px 40px;
   }
}
</style>
